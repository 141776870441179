(function( $ ) {
     
    /******************************
     * Background Images Plugin
     * 
     * if classes .air3-has-background-image.air3-bg-image
     * implies that bg image is on THAT element
     *
     * @returns {jQuery}
     */
    $.fn.setBackgroundImage = function() {

        this.each(function(i, el){

            var $el = $(el);
            var width = 0;          // width of container for background image
            var elBgImage = $el;    // element where background-image goes
            
            // 1.1) If section background image and width will be on the <section>
            // 1.1.2) Or if classes .air3-has-background-image.air3-bg-image implies that bg image is on THAT element
            if( $el.is("section") || $el.hasClass( 'air3-bg-image' ) ) {
                width = $el.width();
            }
            else {

                // 1.2) If background image on widget or column
                // then width and background image will be on the first child <div>
                if( $el.hasClass('elementor-column') || $el.hasClass('elementor-widget') ) {
                    
                    elBgImage = $el.children().first();
                    width = $(elBgImage).width();
                }
            }

            // 2.) get ideal image for container width
            var backgroundImage = getBackgroundImageForWidth( el, width );

            // 3.) set background-image
            if( backgroundImage ) {
                $(elBgImage).css('background-image', 'url("' + backgroundImage + '")');

                /* if to need to set !important
                var elem = $(elBgImage);
                elem[0].style.setProperty('background-image', 'url("' + backgroundImage + '")', 'important');*/
            }
        });
    
        // fire event to use it in other js files on body
        $( 'body' ).trigger( 'air3-set-background-image' );
        
        return this;
    };


    /**
     * Get the ideal image for the container width
     *
     * @param el
     * @param width
     * @returns bool|string url to image
     */
    function getBackgroundImageForWidth( el, width ) {

        var isRetina = window.devicePixelRatio > 1;
        var dataImageSize = 'maximum';
        
        // 1.) get all image sizes => stored in window.imageSizes
        // Important: We expect window.imageSizes to be in the order of ascending width
        // 2.) check which size is needed for this width
        /*for( const [imageSize, maxWidth] of window.imageSizes ) {

            if( width <= maxWidth ) {
                dataImageSize = imageSize;
                break;
            }
            //console.log(`The max width of ${ imageSize } is ${ maxWidth }`);
        }*/
        // rewrite it to work in IE 11
        for( var i = 0; i < window.imageSizes.length; i ++ ) {

           var imageSize = window.imageSizes[i][0];
           var maxWidth = window.imageSizes[i][1];

            if( width <= maxWidth ) {
                dataImageSize = imageSize;
                break;
            }
        }

        // 3.) get device|browsersize => check if sm or xs
        // see: in elementor we can choose different images for tablet (sm) or mobile (xs)
        var device = ''; // default notebook/desktop
        if(window.isTablet) device = 'sm-';
        if(window.isMobile) device = 'xs-';

        // 3.5) @since 1.2.6 url/image are split up so get url to image first
        var url = $( el ).attr('data-url');
       
        // 4.) get url of background-image fromd data attribute
        // data attribute is like this: data-image-src-{2x}-{sm|xs}-{$image-size}
        var image;
        if( isRetina ) {
            image = $(el).attr('data-image-src-2x-' + device + dataImageSize);
            if( typeof image === "undefined" ) {
                // if no image for tablet or mobile => use default
                image = $(el).attr('data-image-src-2x-' + dataImageSize);
            }
        } else {
            image = $(el).attr('data-image-src-' + device + dataImageSize);
            if( typeof image === "undefined" ) {
                // if no image for tablet or mobile => use default
                image = $(el).attr('data-image-src-' + dataImageSize);
            }
        }
      
        if(typeof url == 'string' && typeof image == 'string') {
            return url + image;
        }
        
        return false;
    }
    
    // ----------------------------------------- //
    // Listen to browser size changed trigger
    //
    $( 'body' ).on( 'air3-browser-size-changed', function() {
        $( '.air3-has-background-image' ).setBackgroundImage();
    });
    
    // ----------------------------------------- //
    // Do on initialisation
    //
    function air3BgImageInit() {

        // 1.) get all image sizes
        // 2.) transform Object into array to loop through
        window.imageSizes = Object.entries(air3_variables.imagesizes);
    }

    // on elementor edit|preview 
    if( air3_variables.elementor.isPreviewMode ) {

        // when iframe of preview is ready
        $('iframe#elementor-preview-iframe').ready(function() {
            air3BgImageInit();
            /*elementorFrontend.hooks.addAction('frontend/element_ready/global', function() {
                // in preview background images are not in first childs div
                // see div.elementor-element-overlay
                // @todo solution for this
                $('.air3-has-background').setBackgroundImage();
            });*/
        });
    }
    // only on frontend
    else {
        air3BgImageInit();
        $('.air3-has-background-image').setBackgroundImage();
    }

}(jQuery));