// jQuery Plugin Elementor Margins
// Handles and adjusts margins and paddings set in elementor
// to adjust to the needs of theme air3
// basically it gets the content properly aligned
// set class air3-has-backround to sections/columns/elements with background
// by Britta Weller

(function( $ ) {

    $.elementorMainAir3 = function( element, options ) {

        var defaults = {

            // all changes are made to fit content (also backgrounds) inside content area
            // can be adjusted in child theme via php hooks
            adjustMarginInnerSection: true, // adjusts inner section margin to fit content
            adjustMarginSectionWithBackground: true, // adjusts outer margin of outer section with background
            adjustMarginColumnWithBackground: true, // adjusts outer margin of column with background
            adjustMarginTexteditorWithBackground: true // add padding on texteditor with background color

            // if your plugin is event-driven, you may provide callback capabilities
            // for its events. execute these functions before or after events of your 
            // plugin, so that users may customize those particular events without 
            // changing the plugin's code
            // onFoo: function() {}
        }

        var plugin_bme = this;

        // this will hold the merged default, and user-provided options
        // plugin's properties will be available through this object like:
        // plugin.settings.propertyName from inside the plugin or
        // element.data('elementorMainAir3').settings.propertyName from outside the plugin,
        // where "element" is the element the plugin is attached to;
        plugin_bme.settings = {}

        var $element = $(element); // reference to the jQuery version of DOM element
            //element = element;    // reference to the actual DOM element

        // set new element to get browser defaults for background none
        var noBgBase;
        if( ! $('.air3-fake').length) { // check first if not created yet
            noBgBase = $( '<fake class="air3-fake" />' ).hide().appendTo( 'body' );
        }
        else {
            noBgBase = $( '.air3-fake' );
        }

        // the "constructor" method
        plugin_bme.init_bme = function() {

            // the plugin's final merged properties
            plugin_bme.settings = $.extend({}, defaults, options);
         
            /*if(
                plugin_bme.settings.adjustMarginInnerSection ||
                plugin_bme.settings.adjustMarginSectionWithBackground ||
                plugin_bme.settings.adjustMarginColumnWithBackground ||
                plugin.settings.adjustMarginTexteditorWithBackground
            ) {
                checkMarginSectionColumn();
            }*/
    
            checkSectionColumn();
        }

        // ------------------------------------------------------------------------ //
        // check for removing margin/padding of section or columns
        // and simultaniously add background classes
        //
        // @access private
        // private methods can be called only from inside the plugin like:
        // methodName(arg1, arg2, ... argn)
        // ------------------------------------------------------------------------ //
        var checkSectionColumn = function() {
    
            // 1) check SECTION background
            $element.find('.elementor-section-wrap section.elementor-section').each(function( i, section ) {

                if(plugin_bme.hasBackground( section ) ) {
                    // 1.1) add background class
                    $( section ).addClass( 'air3-has-background' );
                    // 1.2) set class .air3-adjust-margin to all sections with background
                    if( plugin_bme.settings.adjustMarginSectionWithBackground && !$( section ).hasClass( 'elementor-section-stretched' ) ) {
                        $( section ).addClass( 'air3-adjust-margin' );
                    }
                }
    
                // 2) check COLUMN background
                $( section ).find( '.elementor-column' ).children( '.elementor-widget-wrap' ).each( function(i, columnWrap) {
    
                    if( plugin_bme.hasBackground( columnWrap ) ) {
                        // 2.1) add background class
                        $( columnWrap ).parent().addClass( 'air3-has-background' );
                        // 2.2) remove outer margin of column with background
                        if( plugin_bme.settings.adjustMarginColumnWithBackground ) {
                            $( columnWrap ).parent().addClass( 'air3-adjust-margin' );
                        }
                    }
                } );
                
                // 3) check WIDGET background
                $( section ).find( '.elementor-widget' ).each( function(i, widget) {
                    $( widget ).children( '.elementor-widget-container' ).each( function(i, container) {
                        if( plugin_bme.hasBackground( container ) ) {
                            // 3.1) add background class
                            $( container ).parent().addClass( 'air3-has-background' );
                            // 3.2) check: add padding on texteditor with background color
                            if( $(widget).hasClass('elementor-widget-text-editor') ) {
                               $( container ).parent().addClass( 'air3-adjust-margin' );
                            }
                        }
                    } );
                });
                
                // 4) Check: for inner sections to be adjusted
                if( plugin_bme.settings.adjustMarginInnerSection ) {
                    var isInnerSection = $(section).hasClass('elementor-inner-section') ? true : false;
                    if(isInnerSection) {
                        $(section).closest('.elementor-widget-wrap').addClass('air3-column-has-inner-section')
                                  .closest('.elementor-section').addClass('air3-section-has-inner-section');
                    }
                }
            });
        }

        // ------------------------------------------------------------------------ //
        // check if element has a background
        // el   element to check
        // @access public
        //
        // public methods can be called like:
        // plugin.methodName(arg1, arg2, ... argn) from inside the plugin or
        // element.data('elementorMainAir3').publicMethod(arg1, arg2, ... argn) from outside
        // the plugin, where "element" is the element the plugin is attached to;
        // ------------------------------------------------------------------------ //
        plugin_bme.hasBackground = function( el ) {

            var $el = (el.jquery) ? el : $(el);

            var hasBg = (
                $el.css('background-color') != $(noBgBase).css('background-color') ||
                $el.css('background-image') != $(noBgBase).css('background-image')) ?
                true : false;

            return hasBg;
        }

        // make sure elementor is loaded
        $(window).on('elementor/frontend/init', function() {
            
            if( !air3_variables.elementor.isPreviewMode ) {
                // only on frontend
                plugin_bme.init_bme();
            }
            else {
                // when iframe of preview is ready
                $('iframe#elementor-preview-iframe').ready(function() {

                    // -- only on preview -- //
                    plugin_bme.init_bme();

                    // listen to editor active |preview switch
                    // add listener from inside iframe to main.js
                    parent.$('body').on('air3-elementor-mode-switcher-preview', function() {
                         // update preview
                         plugin_bme.init_bme();
                    });
                    // ------------------- //
                });
            }
        });
    }

    // add the plugin to the jQuery.fn object
    $.fn.elementorMainAir3 = function( options ) {
       
        // iterate through the DOM elements we are attaching the plugin to
        return this.each(function() {

            // if plugin has not already been attached to the element
            if( undefined == $(this).data('elementorMainAir3') ) {

                // create a new instance of the plugin
                // pass the DOM element and the user-provided options as arguments
                var plugin_bme = new $.elementorMainAir3(this, options);

                // in the jQuery version of the element
                // store a reference to the plugin object
                // you can later access the plugin and its methods and properties like
                // element.data('elementorMainAir3').publicMethod(arg1, arg2, ... argn) or
                // element.data('elementorMainAir3').settings.propertyName
                $(this).data('elementorMainAir3', plugin_bme);
            }
        });
    }
})(jQuery);