// common javascript functionality
// @codekit-prepend "dev/js/main-common.js"

// elementor related javascript
// @codekit-prepend "dev/js/main-elementor.js"

(function( $ ) {

    // ----------------- //
    // main-elementor.js
    //

    // only evoke plugin elementorMainAir3 if necessary
    if(
        air3_variables.elementor.adjustMarginInnerSection ||
        air3_variables.elementor.adjustMarginSectionWithBackground ||
        air3_variables.elementor.adjustMarginColumnWithBackground ||
        air3_variables.elementor.adjustMarginTexteditorWithBackground
    ) {
        var opts = {
            // adjusts inner section margin to fit content
            adjustMarginInnerSection: air3_variables.elementor.adjustMarginInnerSection,
            // adjusts outer margin of outer section with background
            adjustMarginSectionWithBackground: air3_variables.elementor.adjustMarginSectionWithBackground,
            // adjusts outer margin of column with background
            adjustMarginColumnWithBackground: air3_variables.elementor.adjustMarginColumnWithBackground,
            // add padding on texteditor with background color
            adjustMarginTexteditorWithBackground: air3_variables.elementor.adjustMarginTexteditorWithBackground
        };

        // init plugin outside plugin so it can be overwritten
        $(".elementor").elementorMainAir3(opts);
    }
    // ----------------- //

}(jQuery));

// background image handler
// @codekit-prepend "dev/js/main-background-image.js"
