(function( $ ) {

    /******************************
     * Email Plugin
     *
     * @returns {jQuery}
     */
    $.fn.emailReplace = function() {

        if( this.is("a") ) {
            this.filter('a.mail').each(function() {
                var link = $(this);
                if( link.is('[href]') ) {
                    buildEmailLink(link);
                }
            });
        } else {
            this.find('a').each(function() {
                var link = $(this);
                if( link.is('[href]') && link.attr('href').indexOf('--at--') > -1 ) {
                    buildEmailLink( link );
                }
            });
        }
        
        function buildEmailLink(link) {
            
            link.attr( 'rel', 'nofollow' );
            var linkNoUrl = link.attr('href').split('//').pop();
            link.attr('href', 'mailto:' + linkNoUrl.replace('--at--', '@'));
            link.html( link.html().replace( htmlDecode( '&#8211;at&#8211;' ), '@' ) );
            link.html( link.html().replace( htmlDecode( '--at--' ), '@' ) );
        }

        /**
         * Decrypt html code
         * (like &#8211; back to --)
         * @access private
         */
        function htmlDecode( str_encode ) {
            return jQuery("<div/>").html(str_encode).text();
        }

        return this;
    };

    /**
     * Get browser size
     *
     * @function
     * @return {String} 'sx', 'sm', 'md' or 'lg'
     * @memberOf jQuery
     */
    function getBrowserSize() {

        var size = '';
        var browserWidthPx = window.innerWidth || jQuery(window).width();
        
        if( browserWidthPx < air3_variables.breakpoints.sm ) {
            size = 'xs';
        } else if( browserWidthPx < air3_variables.breakpoints.md ) {
            size = 'sm';
        } else if( browserWidthPx < air3_variables.breakpoints.lg ) {
            size = 'md';
        } else if( typeof air3_variables.breakpoints.xl === "undefined" || browserWidthPx < air3_variables.breakpoints.xl ) {
            size = 'lg';
        } else {
            size = 'xl';
        }

        return size;
    }
    
    /**
     * store global variables
     */
    function air3GlobalVariables() {
        
        window.browserWidth = jQuery( window ).width();
        window.strBrowserSize = getBrowserSize();
        window.isMobile = (window.strBrowserSize == 'xs') ? true : false;
        window.isTablet = (window.strBrowserSize == 'sm') ? true : false;
    }

    /******************************
     * Do on resize window
     */
    function air3OnResize() {
        
        if( getBrowserSize() !== window.strBrowserSize ) {
            
            // set changed globals
            air3GlobalVariables();
            
            // fire event to use it in other js files on body
            $( 'body' ).trigger( 'air3-browser-size-changed' );
        }
    }

    /******************************
     * Do on initialisation
     */
    function air3Init() {

        // --------------------------------------------------------------------- //
        // store global variables
        //
        air3GlobalVariables();
        /*window.browserWidth = jQuery(window).width();
        window.strBrowserSize = getBrowserSize();
        window.isMobile = (window.strBrowserSize == 'xs') ? true : false;
        window.isTablet = (window.strBrowserSize == 'sm') ? true : false;*/
        // --------------------------------------------------------------------- //

        $('a.mail').emailReplace();
        $('.mail.elementor-widget').emailReplace();
    }

    // on elementor edit|preview 
    if( air3_variables.elementor.isPreviewMode ) {

        // when iframe of preview is ready
        $('iframe#elementor-preview-iframe').ready(function() {
            air3Init();
        });
    }
    // only on frontend
    else {
        air3Init();
    }
    
    // On resize browser
    $( window ).resize( air3OnResize );

}( jQuery ));